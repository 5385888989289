.app-input {
  &__icon {
    position: absolute;
    left: 10px;

    svg {
      width: 16px;
      height: 16px;
    }

    svg,
    path {
      fill: #6b7280;
    }
  }
}
