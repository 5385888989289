@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  margin-right: calc(-1*(100vw - 100%));
}

body {
  background-color: #F3F4F6;
  font-family: 'Inter', sans-serif;
}

.App {
}

