.auth-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  &__logo {
    width: 200px;
    margin-bottom: 32px;
  }
}
