.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  cursor: pointer;
  color: #4f46e5;

  &_active {
    background-color: #e0e7ff;
  }

  svg {
    width: 20px;
    height: 20px;

  }
}
