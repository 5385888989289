.app-header {
  &__link {
    @apply border-transparent hover:text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium;

    height: 70px;

    &_active {
      @apply border-indigo-500 text-gray-900;
    }
  }
}
