.app-search {
  position: relative;
  width: 250px;

  &__icon {
    position: absolute;
    left: 8px;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    color: #6b7280;
  }

  input {
    padding-left: 32px;
  }
}
